import i18next, { TFunction, TOptionsBase } from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import viTranslation from "./languages/vi/viTranslation";
import enTranslation from "./languages/en/enTranslation";
import { Paths } from "src/utils/Utils";
import { $Dictionary } from "i18next/typescript/helpers";
import localStorageService from "src/repositories/local-storage/LocalStorageService";

export const defaultLng = localStorageService.getData("languagePreference") ?? "vi";

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    lng: defaultLng,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      vi: {
        translation: viTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
  });

type PreUnderscorePaths<T extends string> = T extends `${infer Pre}_${infer _Post}` ? Pre : T;

type MinusPrefix<T extends string, Prefix extends string> = T extends `${Prefix}.${infer Rest}` ? Rest : never;

type I18nPaths<T extends object, Prefix extends string> = MinusPrefix<PreUnderscorePaths<Paths<T>>, Prefix>;

type ActualPaths<Prefix extends string> = 
  I18nPaths<typeof enTranslation, Prefix> & 
  I18nPaths<typeof viTranslation, Prefix>;

export function getI18t<Prefix extends string>(t: TFunction<"translation", Prefix>){
  return (key: ActualPaths<Prefix>, options?: (TOptionsBase & $Dictionary) | undefined) => {
    return t(key, options);
  };
}