import activityStatusRecords from "src/services/core/log/status-records/ActivityStatusRecords";
import sensorStatusRecord from "src/services/core/log/status-records/SensorStatusRecords";
import systemStatusRecord from "src/services/core/log/status-records/SystemStatusRecords";

const enTranslation = {
  nav: {
    headerRoot: {
      language: "Language",
      login: "Login",
      deleteAccount: "Delete account",
      logOut: "Log out",
      userInformation: "Account",
      deleteAccountPage: {
        warning:
          "Are you sure you want to delete your account? All of your data will also be deleted from the system!",
        irreversible: "This action is irreversible!",
        enterEmail1: 'Enter "',
        enterEmail2: '" to delete your account',
        deleteAccount: "Delete account",
      },
    },
    sideBar: {
      tabs: {
        devices: "Devices",
        members: "Members",
        groups: "Groups",
        logs: {
          label: "Logs",
          sub: {
            activity: "Entry/exit logs",
            sensor: "Sensor logs",
            system: "System logs",
          },
        },
      },
    },
    notFound: {
      pageNotFound: "Page Not Found",
    },
  },
  auth: {
    login: {
      loggingIn: "Logging in...",
      loginSuccessful: "Login successful",
      accountNotActivated:
        "Your account has not been activated. Please check you email to get an activation code.",
      wrongCredentials: "Wrong email or password",
      greeting: "Welcome to KT Lock!",
      greetingRepeat: "Welcome back!",
      password: "Password",
      signIn: "Sign in",
      noAccount: "Do not have an account?",
      signUp: "Sign up",
      forgotPassword: "Forgot your password?",
    },
    signUp: {
      registerAnAccount: "Register an account",
      emailError: {
        used: "The email address has already been used by another account!",
        invalid: "Invalid email address!",
      },
      passwordReenterNotMatch: "Password re-enter does not match password!",
      nameTooLong: "Name length must not be longer than 100 characters!",
      signingUp: "Signing up...",
      signUpSuccess: "Sign up successfully",
      invalidEmailToast:
        "The email address is either invalid or is not accepted by the server. Please try again with another email.",
      unacceptedEmail: "Unaccepted email address!",
      password: "Password",
      yourPassword: "Your password",
      reenterPassword: "Re-enter password",
      reenterPasswordPlaceholder: "Re-enter your password",
      name: "Full name",
      namePlaceholder: "Your full name",
      tacAgreement: "I agree with the ",
      userAgreement: "User agreement",
      privacyPolicy: "Privacy policy",
      and: "and ",
      childrenPrivacyStatement: "Children's privacy statement",
      signUp: "Sign up",
      aggreeToSignUp: "Sign up",
      hasAnAccount: "Already has an account? ",
      login: "Login",
    },
    activateAccount: {
      error: {
        wrongCode: "Wrong activation code!",
        codeNotEntered: "Please fill out the activation code!",
      },
      activatingAccount: "Activating account...",
      accountActivationSuccess: "Account activation successful",
      emailSent:
        "An activation code has been sent to your email, please enter it to finish setting up your account.",
      activationCodeLabel: "Enter activation code",
      submit: "Submit",
    },
    forgetPassword: {
      title: "Recover password",
      requesting: "Sending password recover request...",
      requestSuccess: "Request sent successfully!",
      invalidEmail: "The email is invalid!",
      userNotFound: "Email not found!",
      submit: "Recover password",
    },
    changePasswordWithCode: {
      emailSent:
        "An activation code has been sent to your email, please enter it to change your password.",
      wrongCode: "Incorrect password recovery code!",
      passwordReenterNotMatch:
        "New password re-enter does not match new password!",
      requesting: "Changing password...",
      requestSuccess: "Password changed successfully!",
      title: "Recover password",
      passwordRecoveryCodeLabel: "Password recovery code",
      newPasswordLabel: "New password",
      newPasswordReenterLabel: "Re-enter new password",
      submit: "Change password",
    },
  },
  inApp: {
    groupList: {
      deleting: "Deleting...",
      groupDeleted: "Group {{name}} deleted",
      selectGroup: "Select group:",
      enableEdit: "Enable edit",
      disableEdit: "Díable edit",
      delete: "Delete",
      deleteTitle: "Delete group",
      deleteWarning:
        'Are you sure you want to delete group "{{name}}"? All the devices, members and logs belonging to this group will also be deleted. The data on the device will not be affected.',
      addGroup: {
        title: "Add a group",
        noEmptyName: "Name must not be empty",
        adding: "Adding...",
        additionSuccess: "Additon successful",
        groupName: "Group name",
        groupNamePlaceholder: "Restaurant A",
        add: "Add",
      },
      editGroup: {
        title: "Edit group",
        noEmptyName: "Name must not be empty",
        editing: "Editing...",
        editSuccess: "Edit successful",
        noChange: "Group details weren't changed",
        groupName: "Group name",
        groupNamePlaceholder: "Restaurant A",
        edit: "Edit",
      },
    },
    deviceList: {
      deleting: "Deleting...",
      deleteSuccess: "Delete successful",
      sendingCommand: "Sending command...",
      doorOpened_one: "Door opened: ",
      doorOpened_other: "{{count}} doors opened: {{devices}}",
      deviceNotConnected_one: "Device not connected: {{devices}}",
      deviceNotConnected_other: "{{count}} devices not connected: ",
      addDevice: "Add",
      delete: "Delete",
      openDoor: "Open doors",
      getDevicesFail: "Failed to retrieve the device list.",
      noDevices:
        'You currently manage 0 devices in this group. Press"Add device" to add a new device.',
      deleteTitle: "Delete devices",
      deleteWarning_one:
        "Are you sure you want to delete {{count}} device? All the logs from this device will also be deleted.",
      deleteWarning_other:
        "Are you sure you want to delete {{count}} devices? All the logs from these devices will also be deleted.",
      table: {
        no: "No.",
        serialNumber: "Serial number",
        name: "Name",
        status: "Status",
        online: "Online",
        offline: "Offline",
        action: "Actions",
      },
      addDevicePage: {
        title: "Add a device",
        noEmptySerialNumber: "Serial number must not be empty",
        adding: "Adding...",
        addSuccess: "Additon successful",
        deviceExist: "Device already exists in the system: ",
        serialNumberlabel: "Device serial number",
        passwordLablel: "Device password ",
        blankIfNoPassword: "(leave blank if the device doesn't use a password)",
        nameLabel: "Device name",
        namePlaceholder: "Restaurant A staff door",
        add: "Add",
        serialNotLongEnoughError:
          "The serial number must contain 16 characters.",
        deviceNotConnected: "Device offline",
      },
      editDevicePage: {
        title: "Edit device",
        noEmptySerialNumber: "Serial number must not be empty",
        editing: "Editing...",
        editSuccess: "Edit successful",
        serialNumberlabel: "Device serial number",
        passwordLablel: "Device password ",
        blankIfNoPassword: "(leave blank if the device doesn't use a password)",
        nameLabel: "Device name",
        namePlaceholder: "Restaurant A staff door",
        edit: "Edit",
      },
    },
    managedUserList: {
      deleting: "Deleting...",
      deleteSuccess: "Delete successful",
      addManagedUser: "Add",
      deleteManagedUser: "Delete",
      deleteTitle: "Delete members",
      deleteWarning_one:
        "Are you sure you want to delete {{count}} member? The deletion will also be performed on the following devices:",
      deleteWarning_other:
        "Are you sure you want to delete {{count}} members? The deletion will also be performed on the following devices:",
      defaultDevices: "Default devices:",
      getManagedUsersFailed: "Failed to retrieve the managedUser list.",
      noManagedUsers:
        'You currently manage 0 members in this group. Press "Add member" to add a new member.',
      deviceId: "ID on device",
      internalId: "Internal ID",
      name: "Name",
      password: "Password",
      cardNumber: "Card number",
      role: "Role",
      member: "Member",
      admin: "Admin",
      actions: "Actions",
      showHideColumns_one: "Show/hide columns ({{count}} column hidden)",
      showHideColumns_other: "Show/hide columns ({{count}} columns hidden)",
      addManagedUserPage: {
        title: "Add a member",
        adding: "Adding...",
        addSuccess: "Additon successful",
        deviceIdConflict:
          "This ID is already used by another member in the group",
        deviceIdConflictToast:
          "The ID is already used by another member in the group",
        deviceIdLabel: "ID on device",
        isAdminLabel: "Is admin",
        nameLabel: "Member name",
        internalIdLabel: "Internal ID",
        internalIdDescription:
          "Student ID/ Employee ID/ Identity card number, etc.",
        internalIdPlaceholder: "STF-001923",
        passwordLablel: "Password",
        blankIfNoPassword: "(leave blank if the member doesn't use a password)",
        cardNumberLablel: "Card number",
        blankIfNoCardNumber: "(leave blank if the member doesn't use a card)",
        addToDevice: "Add to devices:",
        add: "Add",
      },
      editManagedUserPage: {
        title: "Edit a member",
        editing: "Editing...",
        editSuccess: "Edit successful",
        deviceIdConflict:
          "This ID is already used by another member in the group",
        deviceIdConflictToast:
          "The ID is already used by another member in the group",
        deviceIdLabel: "ID on device",
        isAdminLabel: "Is admin",
        nameLabel: "Member name",
        internalIdLabel: "Internal ID",
        internalIdDescription: "Student/employee ID, etc.",
        internalIdPlaceholder: "STF-001923",
        passwordLablel: "Password",
        blankIfNoPassword: "(leave blank if the member doesn't use a password)",
        cardNumberLablel: "Card number",
        blankIfNoCardNumber: "(leave blank if the member doesn't use a card)",
        addToDevice: "Edit on devices",
        edit: "Edit",
      },
    },
    logs: {
      shared: {
        getLogFail: "There has been a problem retrieving logs.",
        noLogs: "There are no logs for this time range.",
        from: "From",
        to: "To",
        date: "Date:",
        filter: "Filter",
      },
      activity: {
        status: Object.fromEntries(
          Object.entries(activityStatusRecords).map(([i, v]) => [i, v.en])
        ),
        table: {
          time: "Time",
          deviceName: "Device",
          memberName: "Member",
          status: "Authorization method",
          enterExit: "Enter/Exit",
          enter: "Enter",
          exit: "Exit",
        },
      },
      sensor: {
        status: Object.fromEntries(
          Object.entries(sensorStatusRecord).map(([i, v]) => [i, v.en])
        ),
        table: {
          time: "Time",
          deviceName: "Device",
          status: "Sensor event",
        },
      },
      system: {
        status: Object.fromEntries(
          Object.entries(systemStatusRecord).map(([i, v]) => [i, v.en])
        ),
        table: {
          time: "Time",
          deviceName: "Device",
          status: "System event",
        },
      },
    },
    deviceDetail: {
      loadDeviceFailed: 'There has been a problem loading the device "{{sn}}".',
      openDoor: "Unlock",
      lockDoor: "Lock",
      deviceOffline: "Device offline",
      addManagedUser: "Add member",
      download: "Sync to device",
      upload: "Sync to server",
      uploadSuccess: "Synced successfully",
      downloadSuccess: "Synced successfully",
      noConflictingManagedUsers: "No conflicting members found to sync",
      deviceUpToDate: "The members on the device are already up to date",
      serverUpToDate: "The members on the server are already up to date",
      confirmSync: "Sync",
      downloadTitle_one: "Sync {{count}} member to the device",
      downloadTitle_other: "Sync {{count}} members to the device",
      uploadTitle_one: "Sync {{count}} member to the server",
      uploadTitle_other: "Sync {{count}} members to the server",
      downloadWarning_one:
        "This action will overwrite any conflicting existing member on the device ({{count}} conflicting member found).",
      downloadWarning_other:
        "This action will overwrite any conflicting existing member on the device ({{count}} conflicting members found).",
      uploadWarning_one:
        "This action will overwrite any conflicting existing member on the server ({{count}} conflicting member found).",
      uploadWarning_other:
        "This action will overwrite any conflicting existing member on the server ({{count}} conflicting members found).",
      sync: "Sync",
      cancel: "Cancel",
      table: {
        server: "Server",
        device: "Device ({{name}})",
        id: "ID",
        internalId: "Internal ID",
        name: "Name",
        nameOnDevice: "Name on device",
        password: "Password",
        cardNumber: "Card",
        role: "Role",
        member: "Member",
        admin: "Admin",
        fingerprintCount: "Fingerprint",
      },
      toast: {
        sendingCommand: "Sending command...",
        deleting: "Deleting...",
        doorOpened: "Unlocked",
        doorLocked: "Locked",
        deleteSuccess: "Member deleted",
        wrongPassword: "Wrong password",
        notConnected: "Device not conneceted",
      },
      addManagedUserToDevice: {
        title: 'Add a member to the device "{{name}}"',
        adding: "Adding...",
        addSuccess: "Additon successful",
        idLabel: "ID",
        nameLabel: "Member name",
        isAdminLabel: "Is admin",
        passwordLablel: "Password",
        blankIfNoPassword: "(leave blank if the member doesn't use a password)",
        cardNumberLablel: "Card number",
        blankIfNoCardNumber: "(leave blank if the member doesn't use a card)",
        add: "Add",
      },
    },
    component: {
      deviceDropDown: {
        device_one: "{{count}} device",
        device_other: "{{count}} devices",
        noDevices: "No devices found...",
      },
    },
    userProfile: {
      userProfile: "Account information",
      updating: "Updating account information",
      updateSuccess: "Update success",
      emailUsed: "The email address has already been used by another account!",
      invalidEmail: "Invalid email address!",
      nameLabel: "Full name",
      namePlaceholder: "Your full name",
      update: "Update",
      passwordLabel: "Password",
      changePasswordButton: "Change password",
      changePassword: {
        title: "Change password",
        oldPasswordLabel: "Old password",
        newPasswordLabel: "New password",
        newPasswordReenterLabel: "Re-enter new password",
        submit: "Change password",
        passwordReenterNotMatch:
          "New password re-enter does not match new password!",
        wrongCurrentPassword: "Wrong current password!",
        newPasswordMustBeDifferent:
          "Your new password must not be the same as your old password!",
        changingPassword: "Requesting password change...",
        changePasswordSuccess: "Password changed successfully!",
      },
    },
  },
  components: {
    popup: {
      cancel: "Cancel",
    },
    passwordCriteriaHover: {
      passwordCriteria: {
        title: "The password must satisfy the following conditions:",
        length8: "- Be 8 or more characters long",
        containsNumber: "- Contains atleast a number",
        containsUppercase: "- Contains atleast one uppercase character",
        containsLowercase: "- Contains atleast one lowercase character",
      },
    },
  },
  utils: {
    toastUtils: {
      error: {
        serverError: "Server error",
        serverConnectionError: "Server connection error",
        timeout: "Connection timed out",
        error: "A problem has occured",
      },
      deviceReport: {
        ok_one: "Message sent successfully to device: ",
        ok_other: "Message sent successfully to {{count}} devices: ",
        wrongPassword_one: "Wrong passwords for device: ",
        wrongPassword_other: "Wrong passwords for {{count}} devices: ",
        notConnected_one: "Device not connected: ",
        notConnected_other: "{{count}} devices not connected: ",
        canceled_one: "Operation canceled for device: ",
        canceled_other: "Operation canceled for {{count}} devices: ",
        timeout_one: "Server timeout for device: ",
        timeout_other: "Server timeout for {{count}} devices: ",
        other_one: "There was a problem with device: ",
        other_other: "There were problems with {{count}} devices: ",
      },
      sessionExpired: "Your session has expired, please login again.",
      // buffer line
    },
    validatorUtils: {
      passwordError: {
        notLongEnough: "The password must be 8 or more characters long",
        noNumber: "The password must contain atleast 1 number",
        noLowercase: "The password must contain atleast 1 lowercase letter",
        noUppercase: "The password must contain atleast 1 uppercase letter",
        linebreak: "The password must not contain any linebreak",
      },
    },
  },
};

export default enTranslation;
