import enTranslation from "src/pages/i18n/languages/en/enTranslation";

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isNumberArray(value: any): value is number[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === "number")
  );
}

export function isStringArray(value: any): value is string[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === "string")
  );
}

export function isString(value: any): value is string {
  return typeof value === "string";
}

export type PartialExcept<T, K extends keyof T> = Pick<T, K> &
  Partial<Omit<T, K>>;

export type Paths<T extends object> =
{
  [K in keyof T]: `${Exclude<K,symbol>}${T[K] extends object? `.${Paths<T[K]>}` : ""}`
}[keyof T]

type ReplaceFistTuple<T extends readonly any[], TReplace> = {
  [K in keyof T]: K extends "0" ? TReplace : T[K]
}

export type ReplaceFistParam<F, ReplaceT> = F extends (...args: infer ParamT) => infer R ? 
  (...args: ReplaceFistTuple<ParamT, ReplaceT>) => R :
  never;

